import React from 'react';
import { Grid, Card, CardContent, Typography } from '@mui/material';

const ServicesSection: React.FC<{ services: { title: string; description: string; icon: React.ReactNode }[] }> = ({ services }) => {
  return (
    <Grid container spacing={3} mb={5}>
      {services.map((service, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card>
            <CardContent>
            {/* <img src={require(`../../images/icons/${service.icon}`).default} alt={service.title} width="50px" /> */}
              <Typography variant="h5" gutterBottom>
                {service.title}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {service.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default ServicesSection;
