import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardActions,
  CardMedia,
  Button,
  Typography,
  Grid,
  List,
  ListItem,
  Divider,
  IconButton,
  Drawer,
  Modal,
  Badge,
  Fab,
  AppBar,
  Toolbar,
  TextField,
  Alert,
  Snackbar,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CloseIcon from '@mui/icons-material/Close';
import pro from '../../images/ryzen_5_5500gt.jpg';
import c7_cabinet from '../../images/cabinet_c7_fingers.png';
import xpg_16_3200 from '../../images/xpg_16gb_3200.jpg';
import asus_A520m_k from '../../images/asus_A520M-K.png';
import ssd_500 from '../../images/crucial_BX500_2.5_500gb.png';
import lg24 from '../../images/LG24.jpg';
import logitechWl from '../../images/mouse_logitech_wl.jpg';
import ant_kb from '../../images/ant_gaming_kb.webp';
import KeyboardDoubleArrowLeftRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftRounded';
import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded';
import ShopHeroSection from '../../components/shopHeroSection';
import { Helmet } from 'react-helmet-async';

interface Peripheral {
  id: number;
  name: string;
  price: number;
  category: string;
  image: string;
}

const peripherals: Peripheral[] = [
  { id: 1, name: 'Ant Esports MK1400 Pro Backlit', price: 549, category: 'Peripherals', image: ant_kb },
  { id: 2, name: 'Logitech M235 Wireless Mouse', price: 739, category: 'Peripherals', image: logitechWl },
  { id: 3, name: 'LG 24 Inch (60.4cm) IPS FHD Monitor', price: 7999, category: 'Peripherals', image: lg24 },
  { id: 4, name: 'AMD RYZEN PROCESSOR 5 5500GT', price: 10100, category: 'Processor', image: pro },
  { id: 5, name: 'ASUS MOTHERBOARD A520M-K', price: 4200, category: 'Motherboard', image: asus_A520m_k },
  { id: 6, name: 'XPG RAM 16GB 3200 MHz', price: 2850, category: 'RAM', image: xpg_16_3200 },
  { id: 7, name: 'CRUCIAL SSD 500GB BX500', price: 2500, category: 'SSD', image: ssd_500 },
  { id: 8, name: 'FINGERS METAL C7 CABINET SMPS', price: 2300, category: 'Cabinet', image: c7_cabinet },
  // Add more products with images as needed
];

const categories = ['All', 'Peripherals', 'RAM', 'SSD', 'Processor', 'Motherboard', 'Cabinet'];

const Shopping: React.FC = () => {
  const [cart, setCart] = useState<Map<number, { item: Peripheral; quantity: number }>>(new Map());
  const [selectedCategory, setSelectedCategory] = useState<string>('All');
  const [openLeftDrawer, setOpenLeftDrawer] = useState<boolean>(false);
  const [openCartModal, setOpenCartModal] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    const savedCart = localStorage.getItem('cart');
    const savedCategory = localStorage.getItem('selectedCategory');

    if (savedCart) {
      setCart(new Map(JSON.parse(savedCart)));
    }
    if (savedCategory) {
      setSelectedCategory(savedCategory);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(Array.from(cart.entries())));
    localStorage.setItem('selectedCategory', selectedCategory);
  }, [cart, selectedCategory]);

  const addToCart = (item: Peripheral) => {
    setCart(prevCart => {
      const updatedCart = new Map(prevCart);
      if (updatedCart.has(item.id)) {
        updatedCart.set(item.id, {
          item,
          quantity: updatedCart.get(item.id)!.quantity + 1,
        });
      } else {
        updatedCart.set(item.id, { item, quantity: 1 });
      }
      return updatedCart;
    });
    setSnackbarMessage(`${item.name} added to cart`);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleViewCart = () => {
    const cartItems = Array.from(cart.values()).map(({ item, quantity }) => ({
      name: item.name,
      image: item.image,
      price: item.price,
      quantity: quantity
    }));
    
    navigate('/shop/cart', { state: { cart: cartItems } });
  };

  const updateQuantity = (id: number, quantity: number) => {
    setCart(prevCart => {
      const updatedCart = new Map(prevCart);
      if (quantity > 0) {
        updatedCart.set(id, { ...updatedCart.get(id)!, quantity });
      } else {
        updatedCart.delete(id);
      }
      return updatedCart;
    });
  };

  const proceedToPayment = () => {
    const cartItems = Array.from(cart.values()).map(({ item, quantity }) => ({
      name: item.name,
      image: item.image,
      price: item.price,
      quantity: quantity
    }));
  
    navigate('/shop/cart', { state: { cart: cartItems } });
  };

  const continueShopping = () => {
    setOpenCartModal(false); // Close the cart modal
  };

  const buyNow = (item: Peripheral) => {
    const cartItem = {
      name: item.name,
      image: item.image,
      price: item.price,
      quantity: 1,
    };
  
    navigate('/shop/cart', { state: { cart: [cartItem] } });
  };

  const filteredPeripherals =
    selectedCategory === 'All'
      ? peripherals
      : peripherals.filter(item => item.category === selectedCategory);

  const cartItemCount = Array.from(cart.values()).reduce((sum, entry) => sum + entry.quantity, 0);

  const clearCart = () => {
    setCart(new Map()); // Clears the cart
  };

  return (
    <>
     <Helmet>
        <title>Best Online Computer Store in India | Techkers Hubb</title>
        <meta name="description" content="Shop for computers, accessories, gaming monitors, and more at the best prices in India. Build your PC today!" />
      </Helmet>
    <ShopHeroSection />
      {/* AppBar for Mobile View */}
      <AppBar position="static" sx={{ display: { xs: 'flex', md: 'none' }}}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open left drawer"
            onClick={() => setOpenLeftDrawer(true)}
          >
            <KeyboardDoubleArrowRightRoundedIcon />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'center'}}>
            Explore the products
          </Typography>
        </Toolbar>
      </AppBar>

      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, height: '100vh', padding: { md: '5rem'}}}>
        {/* Left Pane: Filter Sidebar */}
        <Box
          sx={{
            display: { xs: 'none', md: 'block' },
            width: 250,
            padding: 2,
            height: '100%',
            overflowY: 'auto',
            backgroundColor: '#f5f5f5',
            color: '#666',
          }}
        >
          <Typography variant="h6" gutterBottom>
            Categories
          </Typography>
          <List>
            {categories.map(category => (
              <ListItem
                button
                key={category}
                selected={selectedCategory === category}
                onClick={() => setSelectedCategory(category)}
              >
                {category}
              </ListItem>
            ))}
          </List>
          <Divider sx={{ marginY: 2 }} />
          <Button variant="contained" color="primary" onClick={() => setSelectedCategory('All')}>
            Show All
          </Button>
        </Box>

        {/* Mobile Left Drawer */}
        <Drawer
          anchor="left"
          open={openLeftDrawer}
          onClose={() => setOpenLeftDrawer(false)}
          sx={{ display: { xs: 'block', md: 'none' } }}
        >
          <Box
            sx={{
              width: 250,
              padding: 2,
              height: '100%',
              overflowY: 'auto',
            }}
          >
            <IconButton onClick={() => setOpenLeftDrawer(false)} sx={{ marginBottom: 2,  justifyContent: 'end' }}>
              <KeyboardDoubleArrowLeftRoundedIcon />
            </IconButton>
            <Typography variant="h6" gutterBottom>
              Categories
            </Typography>
            <List>
              {categories.map(category => (
                <ListItem
                  button
                  key={category}
                  selected={selectedCategory === category}
                  onClick={() => {
                    setSelectedCategory(category);
                    setOpenLeftDrawer(false); // Close drawer on selection
                  }}
                >
                  {category}
                </ListItem>
              ))}
            </List>
            <Divider sx={{ marginY: 2 }} />
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setSelectedCategory('All');
                setOpenLeftDrawer(false); // Close drawer on selection
              }}
            >
              Show All
            </Button>
          </Box>
        </Drawer>

        {/* Right Pane: Product Display */}
        <Box
          sx={{
            flexGrow: 1,
            padding: 2,
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {/* Hide the title on mobile as it's already in the AppBar */}
          <Grid container spacing={2}>
            {filteredPeripherals.map(peripheral => (
              <Grid item xs={12} sm={6} md={3} key={peripheral.id}>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', color: '#666' }}>
                  <CardMedia
                    component="img"
                    height="200"
                    image={peripheral.image}
                    alt={peripheral.name}
                    sx={{ objectFit: 'contain' }}
                  />
                  <CardContent>
                    <Typography variant="body1" noWrap>
                      {peripheral.name}
                    </Typography>
                    <Typography variant="body1">₹{peripheral.price}</Typography>
                  </CardContent>
                  <CardActions sx={{ marginTop: 'auto', padding: 2 }}>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => addToCart(peripheral)}
                          sx={{ width: '100%', borderRadius: 'none !important' }}
                        >
                          Add to Cart
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => buyNow(peripheral)}
                          sx={{ width: '100%' }}
                        >
                          Buy Now
                        </Button>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      {/* Floating Cart Button */}
      <Fab
        color="primary"
        aria-label="cart"
        onClick={() => setOpenCartModal(true)}
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        <Badge badgeContent={cartItemCount} color="secondary">
          <ShoppingCartIcon />
        </Badge>
      </Fab>

       {/* Snackbar Notification */}
       <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: '100%' }}
          action={
            <>
              <Button
                size="small"
                color="inherit"
                onClick={handleViewCart}
              >
                View Cart
              </Button>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseSnackbar}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          }
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* Cart Modal */}
      <Modal open={openCartModal} onClose={() => setOpenCartModal(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '90%', sm: '80%', md: 500 },
            maxHeight: '80vh',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            overflowY: 'auto',
          }}
        >
          <Typography variant="h6" gutterBottom>
            Your Cart
          </Typography>
          {Array.from(cart.values()).length > 0 ? (
            <>
              {Array.from(cart.values()).map(({ item, quantity }) => (
                <Box key={item.id} sx={{ marginBottom: 2 }}>
                  <Typography variant="body1">
                    {item.name} - ₹{item.price} x {quantity}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
                    <Button
                      variant="outlined"
                      onClick={() => updateQuantity(item.id, quantity - 1)}
                      disabled={quantity === 1}
                      sx={{ marginRight: 1 }}
                    >
                      -
                    </Button>
                    <Typography variant="body1" sx={{ marginX: 1 }}>
                      {quantity}
                    </Typography>
                    <Button
                      variant="outlined"
                      onClick={() => updateQuantity(item.id, quantity + 1)}
                    >
                      +
                    </Button>
                  </Box>
                </Box>
              ))}
              <Divider sx={{ marginY: 2 }} />

              {/* Clear Cart Button */}
              <Button
                variant="outlined"
                color="error"
                onClick={clearCart}
                sx={{ marginBottom: 2, width: '100%' }}
              >
                Clear Cart
              </Button>

              <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={proceedToPayment}
                  sx={{ flexGrow: 1 }}
                >
                  Proceed to Cart
                </Button>
                <Button variant="outlined" onClick={continueShopping} sx={{ flexGrow: 1 }}>
                  Continue Shopping
                </Button>
              </Box>
            </>
          ) : (
            <Typography variant="body2" color="text.secondary">
              Your cart is empty.
            </Typography>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default Shopping;
