import React, { useState } from 'react';
import { Box, Typography, Button, TextField, RadioGroup, Radio, FormControlLabel, FormControl, FormLabel, Container, Grid, Alert } from '@mui/material';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardNumberElement, CardExpiryElement, CardCvcElement, useElements, useStripe } from '@stripe/react-stripe-js';

// Replace with your actual publishable key
const stripePromise = loadStripe('YOUR_STRIPE_PUBLISHABLE_KEY');

const cardElementOptions = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#a0aec0',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

const PaymentForm: React.FC = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentMethod, setPaymentMethod] = useState('card');
  const [email, setEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [cardHolderName, setCardHolderName] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setErrorMessage(null);

    if (!stripe || !elements) return;

    const cardNumberElement = elements.getElement(CardNumberElement);

    if (paymentMethod === 'card' && cardNumberElement) {
      setLoading(true);
      const { error, paymentMethod: stripePaymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumberElement,
        billing_details: {
          name: cardHolderName,
          email,
          phone: mobileNumber,
        },
      });

      setLoading(false);

      if (error) {
        setErrorMessage(error.message ? error.message : null);
        return;
      }

      alert('Payment method created!');
      // Further processing here
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>Payment Details</Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {/* Left side: Email, Mobile Number and Cardholder Name */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Email"
              type="email"
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <TextField
              label="Mobile Number"
              type="tel"
              fullWidth
              margin="normal"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
              required
            />
            <TextField
              label="Cardholder Name"
              type="text"
              fullWidth
              margin="normal"
              value={cardHolderName}
              onChange={(e) => setCardHolderName(e.target.value)}
              required
            />
          </Grid>

          {/* Right side: Payment Method and Card Details */}
          <Grid item xs={12} sm={6}>
            <FormControl component="fieldset" margin="normal">
              <FormLabel component="legend">Payment Method</FormLabel>
              <RadioGroup
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value)}
              >
                <FormControlLabel value="card" control={<Radio />} label="Card Payment" />
                <FormControlLabel value="upi" control={<Radio />} label="UPI" />
              </RadioGroup>

              {/* Show Card Elements only when 'card' is selected */}
              {paymentMethod === 'card' && (
                <Box sx={{ marginTop: 2 }}>
                  <Typography variant="body2" gutterBottom>Card Number</Typography>
                  <CardNumberElement options={cardElementOptions} />
                  
                  <Box sx={{ marginY: 2 }}>
                    <Typography variant="body2" gutterBottom>Expiry Date</Typography>
                    <CardExpiryElement options={cardElementOptions} />
                  </Box>

                  <Box sx={{ marginY: 2 }}>
                    <Typography variant="body2" gutterBottom>CVC</Typography>
                    <CardCvcElement options={cardElementOptions} />
                  </Box>
                </Box>
              )}
            </FormControl>
          </Grid>
        </Grid>

        {/* Error Message */}
        {errorMessage && (
          <Alert severity="error" sx={{ marginY: 2 }}>
            {errorMessage}
          </Alert>
        )}

        {/* Submit Button */}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading || !stripe}
          sx={{ marginTop: 2 }}
        >
          {loading ? 'Processing...' : 'Pay'}
        </Button>
      </form>
    </Box>
  );
};

const Checkout: React.FC = () => {
  return (
    <Container>
      <Elements stripe={stripePromise}>
        <PaymentForm />
      </Elements>
    </Container>
  );
};

export default Checkout;
