import React from "react";
import { Box, Button, Container, Typography, useMediaQuery, useTheme } from "@mui/material";
import heroImage from '../../images/heroImage.png';

const HeroSection: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container maxWidth="xl" disableGutters>
      <Box
        display="flex"
        flexDirection={isMobile ? "column" : "row"}
        padding={isMobile ? "2rem" : "3rem"}
        alignItems="center"
        textAlign={isMobile ? "center" : "left"}
      >
        <Box flex={1} mb={isMobile ? 2 : 0}>
          <Typography variant={isMobile ? "h4" : "h3"} gutterBottom>
            Accelerate with high-value solutions.
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Delivering cutting-edge solutions for businesses in the digital age. <br />From applications development to hardware retailing and server setups,<br /> we offer comprehensive services to meet your needs.
          </Typography>
          <Typography variant="body1" gutterBottom>
            <i>"It's not that we use technology, we live technology." - Godfrey Reggio</i>
          </Typography>
          <Box marginTop="2rem">
            <Button variant="contained" color="primary" size={isMobile ? "small" : "medium"}>
              Get Started
            </Button>
          </Box>
        </Box>
        <Box flex={1} mt={isMobile ? 3 : 0}>
          <img src={heroImage} alt="tech" style={{ width: isMobile ? '100%' : 'auto' }} />
        </Box>
      </Box>
    </Container>
  );
};

export default HeroSection;
