export const services = [
  {
    title: "Web apps",
    description: "Enhance your online presence with our custom web app development services. We create solutions, from dynamic platforms to intuitive customer portals. ",
    icon: '',
  },
  {
    title: "Mobile apps",
    description:  "Take your business mobile with our custom app development services! Elevate your brand, engage your customers, and stay ahead of the competition.",
    icon: '',
  },
  {
    title: " Standalone apps",
    description: "Turn your ideas into digital experiences with our expert app services. Whether a productivity tool or entertainment app, we'll bring your vision to life.",
    icon: '',
  },
  {
    title: "Computer hardware retailing",
    description: "We offer a wide range of computer hardware products, including desktops, laptops, peripherals, and accessories, at competitive prices.",
    icon: '',
  },
  {
    title: "Server Setups",
    description: "Our team is experienced in designing and configuring server setups to ensure reliable performance and security for your business.",
    icon: '',
  },
  {
    title: "Data storage",
    description: "We provides customized data storage setups tailored to your specific needs, ensuring optimal performance, scalability, and security.",
    icon: '',
  },
  // Add more service objects as needed
];
