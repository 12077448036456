import React from 'react';
import { Box, Typography, Button, CardMedia, Container } from '@mui/material';
import heroImg from '../../images/heroImg.jpg';

const ShopHeroSection: React.FC = () => {
  return (
    <Box
      sx={{
        background: 'linear-gradient(rgb(27, 29, 54), rgba(44, 46, 152, 1)), url(https://source.unsplash.com/1600x900/?computer-parts)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff',
        height: { xs: 'auto' },
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
            alignItems: 'center',
            textAlign: { xs: 'center', md: 'left' },
            paddingTop: '2rem',
            paddingBottom: '2rem'
          }}
        >
          {/* Text Section */}
          <Box
            sx={{
              flex: 1,
              marginRight: { md: 4 },
              marginBottom: { xs: 4, md: 0 },
              paddingTop: { xs : 5},
            }}
          >
            <Typography variant="h3" component="h1" gutterBottom>
              High-Quality Computer Parts
            </Typography>
            <Typography variant="h6" component="p" gutterBottom>
              Upgrade your system with the latest components from trusted brands. Shop processors, motherboards, RAM, SSDs, and more!
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{ marginTop: 2 }}
            >
              Shop Now
            </Button>
          </Box>

          {/* Image Section */}
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: { xs: 'center', md: 'flex-end' },
            }}
          >
            <CardMedia
              component="img"
              image={heroImg}
              alt="Computer Parts"
              sx={{
                width: { xs: '100%', md: '80%' },
                maxWidth: 500,
                borderRadius: 2,
                boxShadow: 3,
              }}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default ShopHeroSection;
